.row_container {
  cursor: default !important; // overide reactstrap default
  padding-top: 0;
  padding-bottom: 0;
}

.label {
  width: 5.5rem;
}

.progress_pc_label {
  width: 3rem;
}